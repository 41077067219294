import type { Vendor } from '@repo/db/schema'
import { Icon } from '@repo/ui/components/Icon.js'
import { cn } from '@repo/ui/utils/utils'
import type { ComponentProps } from 'react'
import { useFetcherForm } from '~/hooks/useFetcherForm'
import { ToggleFavoriteVendorSchema } from './routeConfig'

export const FavoriteButton = ({
  isFavorite,
  vendorId,
  size = 'md'
}: {
  isFavorite: boolean
  vendorId: Vendor['id']
  size?: ComponentProps<typeof Icon>['size']
}) => {
  const { submit, optimisticValues } = useFetcherForm({
    schema: ToggleFavoriteVendorSchema,
    intent: ToggleFavoriteVendorSchema.shape.intent.value,
    key: `${ToggleFavoriteVendorSchema.shape.intent.value}_${vendorId}`,
    config: { action: '/api/favorites' }
  })

  const optimisticIsFavorite = optimisticValues ? optimisticValues.toggleFavorite : isFavorite

  return (
    <div className="contents">
      <button
        type="button"
        onClick={() =>
          submit({
            vendorId: vendorId,
            toggleFavorite: optimisticIsFavorite ? 'off' : 'on'
          })
        }
      >
        <Icon
          name={optimisticIsFavorite ? 'star-solid' : 'star-empty'}
          className={cn(
            optimisticIsFavorite ? 'text-amber-500' : 'text-muted-foreground',
            'hover:scale-105 transition-all duration-300'
          )}
          size={size}
        />
      </button>
    </div>
  )
}
