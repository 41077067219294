import { type ComponentProps, type ElementRef, forwardRef } from 'react'
import { Badge } from './Badge'

type TagProps = Omit<ComponentProps<typeof Badge>, 'variant'> & {
  backgroundColor: string
  textColor: string | null
}

export const Tag = forwardRef<ElementRef<typeof Badge>, TagProps>(
  ({ backgroundColor, textColor, ...props }, ref) => {
    return (
      <Badge
        ref={ref}
        {...props}
        style={{
          backgroundColor,
          color: textColor ?? '#fff'
        }}
      />
    )
  }
)
